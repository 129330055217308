import { FreeTrialInviteDialogComponent } from '../../shared/dialogs/free-trial-invite-dialog/free-trial-invite-dialog.component';
import { SupportDialogComponent } from '../../shared/dialogs/support-dialog/support-dialog.component';
import { DeviceUUID } from 'device-uuid';
import { User, UserDevice } from '../interfaces/user';
import { ShowReferralDialogComponent } from '../../shared/dialogs/show-referral-dialog/show-referral-dialog.component';
import { InvalidLicenseForActionDialogComponent } from '../../shared/dialogs/invalid-license-for-action-dialog/invalid-license-for-action-dialog.component';
import { TranslocoService } from '@jsverse/transloco';
import { Auth, idToken, user, User as FirebaseUser } from '@angular/fire/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { UiService } from '../ui-service/ui.service';
import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import DeviceDetector from 'device-detector-js';
import moment from 'moment';
import { Link } from '../interfaces/link';
import { LottieInfoDialogComponent } from '../../shared/dialogs/lotti-info-dialog/lottie-info-dialog.component';
import { interval, Observable, of, ReplaySubject, take, throwError, Unsubscribable } from 'rxjs';
import { catchError, delay, filter, map, retry, switchMap, tap } from 'rxjs/operators';
import { FutDialogService } from '../dialog-service/fut-dialog.service';
import { UserPlatform } from '../interfaces/user-platform';
import { apiUrl, createCustomHeader } from '../util/http.util';
import { USER_DEFAULT_IMAGE } from '../config/config.model';
import { sanitizeInput } from '../util/string.util';
import { getTime } from '../util/date.util';
import { TypeFormResponse } from '../interfaces/typeform-response';
import { SidenavElement } from '../../shared/sidenav/model/sidenav.model';

const USER_CACHE_KEY = 'user_info';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly auth = inject(Auth);
  private readonly userFire$ = user(this.auth);
  private readonly idToken$ = idToken(this.auth);

  user!: User;

  fireUser!: FirebaseUser;
  onUserLoaded: ReplaySubject<User> = new ReplaySubject<User>(1);

  private lastSeenUpdate$?: Unsubscribable;

  constructor(
    private http: HttpClient,
    private dialog: FutDialogService,
    private swPush: SwPush,
    private uiService: UiService,
    private translate: TranslocoService
  ) {
    const item = sessionStorage.getItem(USER_CACHE_KEY);
    if (item) {
      this.user = JSON.parse(item) as User;
      this.onUserLoaded.subscribe(user => (this.user = user));
    }

    this.getFirebaseUser().subscribe(() => {
      if (!this.lastSeenUpdate$) {
        this.updateLastSeen();
        this.lastSeenUpdate$ = interval(60 * 60 * 1000).subscribe(() => this.updateLastSeen()); // Every hour
      }
    });
  }

  // OBSERVABLE
  public getFirebaseUser(): Observable<FirebaseUser> {
    return this.userFire$.pipe(
      take(1),
      switchMap(user => {
        const ret = user;
        if (!ret) {
          return throwError(() => new Error('User not found'));
        }
        this.fireUser = ret;
        return of(ret);
      })
    );
  }

  public getAuthToken(): Observable<string> {
    return this.idToken$.pipe(
      take(1),
      switchMap(token => {
        if (!token) {
          return throwError(() => new Error('Token not found'));
        }
        return of(token);
      })
    );
  }

  public getQuestionResults(): Observable<{ [key: string]: UserQuestionResult }> {
    return this.http.get<{
      [key: string]: UserQuestionResult;
    }>(apiUrl + '/users/adaptive/questions_results');
  }

  public getLabel(forced = false): Observable<Array<string>> {
    return this.getFuturaUser(forced).pipe(map(user => this.getUserLabelsInstant(user)));
  }

  public clearFuturaUserCache(): void {
    sessionStorage.removeItem(USER_CACHE_KEY);
  }

  public getFuturaUser(forced = false, fromBase = false): Observable<User> {
    if (!forced) {
      const userStringify = sessionStorage.getItem(USER_CACHE_KEY);
      if (userStringify != null) {
        const cacheUser = JSON.parse(userStringify) as User;
        this.onUserLoaded.next(cacheUser);
        return of(cacheUser);
      }
    }
    return this.http.get<User>(apiUrl + '/users/').pipe(
      switchMap(user => {
        if (!fromBase) {
          return of(this.fixUser(user));
        }

        return this.http.get<User>(apiUrl + '/users/after_login').pipe(
          tap(() => {
            localStorage.removeItem('invitor');
            localStorage.removeItem('invitor_type');
          })
        );
      }),
      tap(user => {
        sessionStorage.setItem(USER_CACHE_KEY, JSON.stringify(user));
        this.onUserLoaded.next(user);
      })
    );
  }

  private fixUser(user: User): User {
    if (user.content.nickname == undefined || user.content.nickname == '') {
      user.content.nickname = `${user.content.name}_${user.content.surname}`;
    }
    if (!user.content.platforms) {
      user.content.platforms = {};
    }
    if (user.content.platforms[environment.platform] == undefined) {
      user.content.platforms[environment.platform] = {
        community_points: 0,
        users_invited: [],
        evaluations: {},
        selected_profile: undefined,
      } as unknown as UserPlatform;
    }
    if (user.content.platforms[environment.platform].first_login == undefined) {
      user.content.platforms[environment.platform].first_login = true;
    }
    if (user.content.tutorials_seen == undefined) {
      user.content.tutorials_seen = {};
    }

    if (user.content.platforms[environment.platform].first_login) {
      localStorage.setItem('first_login', 'true');

      if (user.content.platforms[environment.platform] == undefined) {
        user.content.platforms[environment.platform] = {
          first_login: false,
        } as unknown as UserPlatform;
      }

      user.content.platforms[environment.platform].first_login = false;
    }

    return user;
  }

  public updateFuturaUser(user: User): Observable<void> {
    const content = user.content;
    content.nickname = sanitizeInput(content.nickname);

    return this.http.put<void>(apiUrl + '/users/', { content }).pipe(
      switchMap(() => this.getFuturaUser(true)),
      map(() => {
        this.clearFuturaUserCache();
        this.setLanguage(content.language || environment.language);
      })
    );
  }

  public getReferral(): Observable<string> {
    return this.http
      .post<{
        link: string;
      }>(apiUrl + '/users/referral', undefined)
      .pipe(map(res => res.link));
  }

  getUserImage(user: User): string {
    return user.content.image.url == '' ? USER_DEFAULT_IMAGE : user.content.image.url;
  }

  getUserDeviceInfo(): Observable<UserDevice> {
    return this.http
      .get<{
        ip: string;
      }>(environment.be_url + '/users/ip')
      .pipe(
        map(ip => ip.ip),
        catchError(err => {
          console.log('ip error', { err });
          return of('');
        }),
        map(ip => {
          const device = new DeviceDetector().parse(navigator.userAgent);
          const uuid = new DeviceUUID().get();

          const deviceInfo: UserDevice = {
            uuid,

            browser: device.client?.name || '',
            browser_version: device.client?.version || '',
            browser_type: device.client?.type || '',

            os: device.os?.name || '',
            os_version: device.os?.version || '',
            os_platform: device.os?.platform || '',

            device_brand: device.device?.brand || '',
            device_model: device.device?.model || '',
            device_type: device.device?.type || '',

            ip,
            user_agent: navigator.userAgent,
          };

          return deviceInfo;
        })
      );
  }

  getUserCategory(): Observable<Array<string>> {
    return this.http.get<Array<string>>(apiUrl + '/users/category');
  }

  updateImage(image: File, user: User): Observable<void> {
    const formData = new FormData();
    formData.append('file', image);
    const type = image.type.split('/')[1];

    return this.http.post<void>(apiUrl + '/users/profile_pic', formData, {
      headers: new HttpHeaders({
        FileName: user.partition_id + '.' + type,
      }),
    });
  }

  activeLicense(code: string): Observable<User> {
    return this.getFuturaUser().pipe(
      switchMap(user => {
        const user_licenses = user.content.licenses;
        if (user_licenses && user_licenses.some(license => license.code == code)) {
          this.dialog.open(LottieInfoDialogComponent, {
            dimension: 'sm',
            matConf: {
              data: {
                infoTitle: 'profile.license.errors.code_already_activated.title',
                infoSubTitle: 'profile.license.errors.code_already_activated.subtitle',
                lottiePath: './assets/lotties/error.json',
              },
            },
          });
          return of(user);
        }
        return this.http
          .get(
            apiUrl + '/users/active_license',
            createCustomHeader({
              target_resource: code,
            })
          )
          .pipe(
            switchMap(() => {
              sessionStorage.removeItem(USER_CACHE_KEY);
              return this.getFuturaUser(true);
            }),
            tap(() => this.uiService.updateSidenavElements()),
            catchError(err => {
              switch (err.status) {
                case 400:
                  this.uiService.errorToast({ title: this.translate.translate('profile.license.errors.code_already_used') });
                  break;
                case 404:
                  this.uiService.errorToast({ title: this.translate.translate('profile.license.errors.code_not_found') });
                  break;
                case 401:
                  this.uiService.errorToast({ title: this.translate.translate('profile.license.errors.code_already_activated') });
                  break;
                default:
                  this.uiService.errorToast({ title: err.message });
                  break;
              }

              return throwError(() => new Error(err));
            })
          );
      })
    );
  }

  openSupportDialog() {
    return this.dialog.open(SupportDialogComponent, {
      dimension: 'md',
      matConf: { backdropClass: 'dialog-mobile-no-padding' },
    });
  }

  getUserLabelsInstant(user: User): Array<string> {
    let labels = new Array<string>();
    for (const license of user.content.licenses || []) {
      if (!license.active) continue;
      if (license.expire != undefined && license.expire <= getTime()) continue;
      labels = labels.concat(license.labels);
    }
    return labels;
  }

  setLanguage(lang: string) {
    if (lang == 'it') {
      this.translate.setActiveLang('it');
      this.translate.setDefaultLang('it');
      registerLocaleData(localeIt);
      moment.locale('it');
    }
    if (lang == 'en') {
      this.translate.setActiveLang('en');
      this.translate.setDefaultLang('en');
      registerLocaleData(localeEn);
      moment.locale('en');
    }
    localStorage.setItem('language', lang);
  }

  checkLables(user_lables: Array<string>, lables: Array<string>, whitelist: boolean | undefined): boolean {
    user_lables = user_lables.filter(l => l);
    lables = lables.filter(l => l);

    if (whitelist) {
      if (lables.length == 0) return false;
      return lables.some(lable => user_lables.includes(lable));
    } else return !lables.some(lable => user_lables.includes(lable));
  }

  public getLocked(labelType: { lables: string[]; whitelist: boolean } | undefined): Observable<boolean> {
    return this.getLabel().pipe(
      map(userLabels => {
        const configLabels = labelType;
        return !this.checkLables(userLabels || [], configLabels?.lables || [], configLabels?.whitelist);
      })
    );
  }

  enablePushNotification() {
    if (!this.swPush.isEnabled) {
      console.error('Notifiche non abilitate!');
      return;
    }
    this.swPush
      .requestSubscription({
        serverPublicKey: environment.pn_public_key,
      })
      .then(data => {
        this.getFuturaUser()
          .pipe(
            filter(user => !(user.content.push_keys || []).map(key => key.keys.auth).includes(data.toJSON().keys!['auth'])),
            switchMap(() => this.http.post(apiUrl + '/users/add_push_notification_keys', data.toJSON()))
          )
          .subscribe();

        this.swPush.notificationClicks.subscribe(({ action, notification }) => {
          this.notificationClick(notification.data.notification_id, action);
        });
      })
      .catch(err => console.error('Error', err));
  }

  notificationClick(notification_id: string, context: string) {
    context = context || 'notification';
    this.http
      .post(
        apiUrl + '/users/clicked_notification',
        { click_context: context },
        createCustomHeader({
          target_resource: notification_id,
        })
      )
      .subscribe();
  }

  missingLicenseDialog(element?: { name: string; type: 'page' | 'category' }) {
    this.dialog.openDialogOrBottomSheet(InvalidLicenseForActionDialogComponent, {
      dimension: 'md',
      matConf: {
        panelClass: 'dialog-overflow-hidden',
        data: {
          element,
        },
      },
    });
  }

  freeTrialInviteDialog() {
    return this.dialog.open(FreeTrialInviteDialogComponent, {
      dimension: 'md',
      matConf: { panelClass: 'dialog-overflow-hidden-x' },
    });
  }

  showReferralDialog() {
    this.dialog.open(ShowReferralDialogComponent, { dimension: 'md' });
  }

  isPaidUser(user: User): boolean {
    return (user.content.licenses || []).some(
      license => license.subscription_id && license.active && (license.expire == undefined || license.expire >= getTime())
    );
  }

  getUserInvitedCount(user: User): number {
    return (user.content.users_invited || []).length + (user.content.users_link_used || []).length;
  }

  getShorterInfo(token_shorter: string): Observable<ShorterInfo> {
    return this.http.get<ShorterInfo>(
      apiUrl + '/users/shorten',
      createCustomHeader({
        custom_params: { Shorter: token_shorter },
      })
    );
  }

  getResults(): Observable<Record<string, UserResult>> {
    return this.http.get<{
      [key: string]: UserResult;
    }>(apiUrl + '/users/adaptive/results');
  }

  useLink(link: string): Observable<Link> {
    return this.http.get<Link>(apiUrl + '/links/' + link);
  }

  private updateLastSeen() {
    this.http.post(apiUrl + '/users/last_seen', {}).subscribe();
  }

  public typeformResponse(formId: string, responseId: string): Observable<TypeFormResponse> {
    // return of('').pipe(delay(2000), switchMap(() => this.http
    //   .get<TypeFormResponse>(apiUrl + '/users/typeform/response', createCustomHeader({ target_resource: responseId, custom_params: { 'Form-Id': formId } }))
    //   .pipe(retry(3))}};
    return of('').pipe(
      delay(2000),
      switchMap(() =>
        this.http.get<TypeFormResponse>(
          apiUrl + '/users/typeform/response',
          createCustomHeader({ target_resource: responseId, custom_params: { 'Form-Id': formId } })
        )
      ),
      retry(3)
    );
  }
}

interface ShorterInfo__challenge {
  type: 'challenge';
  test_instance: string;
  test_partition: string;
  test_resource: string;
  user: string;
  vertical: string;
}

interface ShorterInfo__prefab {
  type: 'prefab';
  prefab_uuid: string;
}

interface ShorterInfo__referral {
  type: 'referral';
  user_uuid: string;
}

export interface UserResult {
  amount_times_seen: number;
  blank: number;
  correct: number;
  wrong: number;
  last_result: {
    blank?: number;
    correct?: number;
    wrong?: number;
  };
}

export interface UserQuestionResult {
  correct_count: number;
  wrong_count: number;
  blank_count: number;
}

export type ShorterInfo = ShorterInfo__prefab | ShorterInfo__referral | ShorterInfo__challenge;

export interface CustomAction {
  action: string;
  title: string;
  clicked_count: number;
  on_notification_click: FutNotificationAction;
}

export interface FutNotificationAction {
  type: 'open' | 'prefab' | 'empty';
  url: string;
  prefab: string;
}
