<fut-base-dialog *transloco="let translate">
  <fut-icon-title state="NONE" [iconTitle]="iconTitle()" image="/assets/emoji/unlock.png"></fut-icon-title>

  <div class="d-flex flex-column fut-gap-24 fut-m-t-24">
    @if (lockName()) {
      <div class="dark-tooltip fut-p-16">
        <div class="d-flex fut-gap-8 align-items-center">
          <app-alice-animations class="alice-width" type="static"></app-alice-animations>
          <div>{{ translate(aliceMessage()) }}</div>
        </div>
      </div>
    }

    <div class="d-flex flex-column fut-p-16 fut-gap-8 fut-text-light secondary-box">
      <h3 class="fut-h3">{{ translate('dialogs.invalid_license.list.title') }}</h3>
      <div class="d-flex fut-gap-8 align-items-center">
        <mat-icon fontSet="material-symbols-outlined">check</mat-icon>
        <span [innerHTML]="translate('dialogs.invalid_license.list.1')"></span>
      </div>
      <div class="d-flex fut-gap-8 align-items-center">
        <mat-icon fontSet="material-symbols-outlined">check</mat-icon>
        <span [innerHTML]="translate('dialogs.invalid_license.list.2')"></span>
      </div>
      <div class="d-flex fut-gap-8 align-items-center">
        <mat-icon fontSet="material-symbols-outlined">check</mat-icon>
        <span [innerHTML]="translate('dialogs.invalid_license.list.3')"></span>
      </div>
    </div>
  </div>

  <div *transloco="let translate" class="d-flex flex-column fut-gap-24 fut-m-t-24" actions>
    <button class="fut-btn fut-btn-accent w-100" (click)="goProducts()">{{ translate('dialogs.invalid_license.action.unlock_now') }}</button>
    <div class="d-flex flex-row justify-content-between align-items-center">
      <span class="fut-text-light">{{ translate('dialogs.invalid_license.sub_content.title') }}</span>
      <button class="fut-btn fut-btn-link fut-text-secondary-900 insert-code-btn" (click)="insertCode()">{{ translate('profile.license.cta') }}</button>
    </div>
  </div>
</fut-base-dialog>
