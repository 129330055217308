import { Component, computed, input, output } from '@angular/core';
import { Router } from '@angular/router';
import { InsertLicenseCodeDialogComponent } from '../../dialogs/insert-license-code-dialog/insert-license-code-dialog.component';
import { FutDialogService } from '../../../core/dialog-service/fut-dialog.service';
import { TranslocoModule } from '@jsverse/transloco';
import { BaseDialogComponent } from '@futura/futura-ui/dialog';
import { IconTitleComponent } from '@futura/futura-ui/icon-title';
import { TranslateModel } from '@futura/futura-ui/core';
import { MatIcon } from '@angular/material/icon';
import { AliceAnimationsComponent } from '../../alice-animations/alice-animations.component';

@Component({
  selector: 'app-invalid-license-for-action',
  templateUrl: './invalid-license-for-action.component.html',
  styleUrls: ['./invalid-license-for-action.component.scss'],
  standalone: true,
  imports: [TranslocoModule, BaseDialogComponent, IconTitleComponent, MatIcon, AliceAnimationsComponent],
})
export class InvalidLicenseForActionComponent {
  public readonly iconTitle = input<TranslateModel | string>('');
  public readonly lockName = input<string>(''); // this can only have values of name field from sidenavElements type SidenavElement
  public readonly lockType = input<string>('');
  public readonly goProductChange = output<void>();
  public readonly insertCodeChange = output<void>();

  public readonly aliceMessage = computed(() => {
    const lockName = this.lockName();
    const lockType = this.lockType();

    if (!lockName) {
      return '';
    }

    if (lockType === 'category') {
      return 'dialogs.invalid_license.content.category';
    }

    return 'dialogs.invalid_license.alice.' + lockName;
  });

  constructor(
    private router: Router,
    private dialog: FutDialogService
  ) {}

  goProducts(): void {
    this.router.navigate(['products']).then();
    this.goProductChange.emit();
  }

  insertCode(): void {
    this.dialog.open(InsertLicenseCodeDialogComponent, { dimension: 'sm' });
    this.insertCodeChange.emit();
  }
}
